<template>
  <div>
    <div class="top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }" @click.native="change">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path:'/news'}">新闻报道</el-breadcrumb-item>
        <el-breadcrumb-item>2019（第三届）全球青年创新大会金领奖年度盛典</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main">
      <div class="left">
        <h2>2019（第三届）全球青年创新大会金领奖年度盛典</h2>
        <div class="second">
          <div class="time-img"><img src="@/assets/img/time.jpg" alt=""></div>
          <span class="time">2019/10/24</span>
          <span class="time-title">全球青年创新大会</span>
          <span class="gold">金领奖
            <img src="@/assets/img/media.png" alt="">
          </span>
        </div>
        <p class="third-p1">2019年10月24日，第三届全球青年创新大会在京召开，“金领奖”年度颁奖盛典同期举行。本届大会由科技部创新中心、中关村管委会、天津高新区、上海张江高科技园区、中国北京（海淀）留学人员创业园、中国产业创新联盟等支持，由创新观察网联合国内55家行业媒体、120家知名创投机构联合发起举办，并得到22家科技园区、15个省市政府招商机构大力协助。本届会议的主题是“蝶变新商业之路”。</p>
        <div class="firth">
          <div><img src="@/assets/img/jinling1.jpg" alt=""></div>
        </div>
        <p class="fourth-p2">来自北京、上海、深圳、天津、杭州等22个城市的近1500位企业界人士出席大会。</p>
        <div class="firth">
          <div><img src="@/assets/img/jinling2.jpg" alt=""></div>
        </div>
        <p class="fourth-p2">大会期间举办了“金领奖”年度颁奖盛典，揭晓了四大年度创新影响力奖项，爱玩特-智能AI全自动金融大数据分析，以其领先的全自动AI金融大数据分析系统以及广阔的应用前景，入选“金领奖”年度最具成长力创新企业TOP50。</p>
        <div class="firth">
          <div><img src="@/assets/img/jinling3.png" alt=""></div>
        </div>
        <p class="fourth-p2">爱玩特-智能AI全自动金融大数据分析创始人雷宗先生作为大会特邀嘉宾，发表了题为《什么主题。看PPT》的主题演讲。</p>
        <div class="firth">
          <div><img src="@/assets/img/jinling4.png" alt=""></div>
        </div>
        <p class="fourth-p2 fourth-center">爱玩特创始人雷宗先生在大会做主题演讲</p>
        <p class="fourth-p2">据了解，今年的“金领奖”年度最具成长力创新企业TOP50的评选更加注重企业的成长性、创新性和未来的发展前景。同时入选的企业有闪送、分贝通、车轮、叮当快药、Analysys易观、热云数据、通明传媒等优秀企业。</p>
        <p class="fourth-p2">据悉，本届“金领奖”年度评选历时5个月，共收到全国632家企业的申请材料，256家企业进入初选；经组委会专家评审团终审投票，最终产生本次“金领奖” 年度影响力最终榜单。本届“金领奖”评审专家来自IDG资本、真格基金、红杉资本、英诺天使基金、星瀚资本、华兴资本、创享投资等63家知名创投机构；并得到北京、上海、天津、杭州、深圳等地政府双创部门大力支持。</p>
        <p class="fourth-p2">据介绍，爱玩特专注于金融领域的人工智能大数据技术，通过结合人工智能、大数据和机器学习，独立研发出了全自动AI金融大数据分析系统。该系统主要应用于智能投研、智能投顾、智能风控、智能营销、智能客服等金融场景。爱玩特推出的主打产品“一刀智投”，致力于解决“中国十个人炒股九个人赔钱”的痛点。</p>
        <p class="fourth-p2">爱玩特的创始团队十分亮眼，创始人雷宗先生毕业于北京大学，并获得美国华盛顿圣路易斯大学金融数学硕士，曾在美国Putnam Investments公司从事量化基金管理。雷宗从波士顿带回来的原生团队，均有丰富的金融从业经验。</p>
        <p style="text-align:right;margin-top:2rem;margin-bottom:0rem">原文链接：<a href="http://www.zvc360.com/plus/view.php?aid=547">http://www.zvc360.com/plus/view.php?aid=547</a></p>
      </div>

      <div class="right">
        <h3>相关报道</h3>
        <ul>
          <li>
            <a href="newone"><img src="@/assets/img/u303.jpg" alt=""></a>
            <div class="middle">
              <a href="newone" class="h4">【U30进行时】创青春-中关村U30 2019年度优胜者榜单在中关村论坛发布</a>
              <span class="middle-p1">中关村U30</span>
              <span class="middle-p2">优胜者
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/10/16 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="neweight"><img src="@/assets/img/867.jpg" alt=""></a>
            <div class="middle">
              <a href="neweight" class="h4">中国（小谷围）人工智能创新创业大赛圆满落下帷幕</a>
              <span class="middle-p1">人工智能创新创业比赛</span>
              <span class="middle-p2">优胜奖
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/1/10 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
          <li>
            <a href="newseven"><img src="@/assets/img/869.jpg" alt=""></a>
            <div class="middle">
              <a href="newseven" class="h4">创新创业大赛|决赛圆满告捷，冠军花落谁家</a>
              <span class="middle-p1">青年人才创新创业大赛</span>
              <span class="middle-p2">第三名
                <img src="@/assets/img/media.png" alt="">
              </span>
            </div>
            <div class="time">
              <span class="time-word">2019/3/29 </span>
              <img src="@/assets/img/time.jpg" alt="">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      currentRouter: state => state.nav.currentRouter
    })
  },
  methods: {
    ...mapActions({
      changeNavActive: 'nav/changeNavActive'
    }),
    change() {
      this.changeNavActive('首页')
      console.log(1)
    }
  }

}
</script>

<style lang="scss" scoped>
.fourth-center{
  text-align: center;
}
p{
    text-align: left;
    text-indent:2em;
}
img{
     display: block;
}
.top{
  margin-top: 65px;
  // padding: 0 205px;
  padding-left: 5%;
  width: 100%;
  height: 70px;
  background-color: #f2f2f2;
}
.el-breadcrumb {
    line-height: 70px;
}

.main{
    margin:auto;
    padding: 30px;
    width: 1420px;
    overflow: hidden;
}
.left{
    width: 65%;
    padding: 30px;
    float: left;
    overflow: hidden;
    background-color: #ffffff;
	box-shadow: 0px 0px 10px rgba(4, 0, 0, 0.15);
    border-radius: 20px;
    .second{
        overflow: hidden;
        margin-top: 40px;
    }
    .time-img{
        width: 20px;
        height: 20px;
        float: left;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .time{
        float: left;
        margin-left:10px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        color: #999999;
    }
    .time-title{
        background-color: #eff3f5;
        border-radius: 4px;
        float: left;
        font-size: 14px;
        padding: 8px 11px 8px 9px;
        line-height: 10px;
        letter-spacing: 1px;
        color: #999999;
        margin-left: 40px;
        margin-right: 20px;
    }
    .gold{
    background-color: #ffd5d5;
    float: left;
    border-radius: 4px;
	font-size: 14px;
    padding: 8px 11px 8px 9px;
    line-height: 10px;
	letter-spacing: 1px;
    color: #ff3333;
    position: relative;
    img{
        position: absolute;
        top: 0;
        left: -14px;
    }
    }
    .third-p1{
    margin-top: 60px;
    font-size: 16px;
	line-height: 30px;
	letter-spacing: 0px;
	color: #666666;
    }
    .fourth{
        padding: 0 14px;
        div img{
            width: 375px;
            height: 251px;
        }
        .fourth-img1{
            margin-right: 14px;
            float: left;
        }
    }
       .fourth-p2{
           margin-top: 16px;
            font-family: PingFang-SC-Bold;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .fourth-p3{
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #666666;
        }
        .firth{
            padding: 0 14px;
            div img{
                width: 100%;
                height: 511px;
            }
        }
}
ul{
  padding: 0
}
.right{
    width: 21.5%;
    margin-left: 25px;
    float: left;
    overflow: hidden;
    h3{
    font-size: 26px;
	line-height: 48px;
	letter-spacing: 0px;
    color: #000000;
    text-align: left;
    margin-left: 30px;
    position: relative;
    }
    h3::before{
        display: block;
        content: '';
        width: 6px;
        height: 33px;
        background-color: #ff3333;
        position: absolute;
        top: 8px;
        left: -20px;
    }
    ul li{
        width: 100%;
        padding: 10px;
        background-color: #eff3f5;
        border-radius: 8px;
        margin: 15px 0;
        overflow: hidden;
        img{
            width: 100%;
            height: 230px;
            border-radius: 8px;
        }
        .middle{
          width: 100%;
          margin-left: 8px;
          overflow: hidden;
          margin-bottom: 25px;
          span{
              float: left;
              padding: 0 9px;
          }
          .middle-p1{
              background-color: #ffffff;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #999999;
          }
          .middle-p2{
              background-color: #ffd5d5;
              border-radius: 4px;
              font-size: 14px;
              line-height: 30px;
              letter-spacing: 1px;
              color: #ff3333;
              margin-left: 25px;
              position: relative;
              img{
                  width: 20px;
                  height: 26px;
                  position: absolute;
                  top: 1px;
                  left: -15px;
              }
          }
        }
        .h4{
          display: block;
            width: 90%;
            font-size: 18px;
            line-height: 30px;
            letter-spacing: 0px;
            color: #333333;
            text-align: left;
            margin: 16px 0;
        }
        .time{
            overflow: hidden;
            margin-right: 8px;
            img{
                width: 20px;
                height: 20px;
                float: right;
            }
            .time-word{
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 1px;
                color: #999999;
                float: right;
                margin-left: 15px;
                margin-right: 5px;
            }
        }
    }
}
a:hover{
      text-decoration:none;
      color: #e45151  !important;
      transition: all 0.3s;
}
</style>
